.Editor{

  width: 900px;
  margin-top: 50px;
  margin-left: 20%;
}

.confirmation-popup {
  position: fixed;
  top: 20px; /* Adjust this value to set the distance from the top */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 999; /* Adjust the z-index as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

  
  .confirmation-popup p {
    margin-bottom: 15px;
  }
  
  .confirmation-popup button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .confirmation-popup button:hover {
    background-color: #e0e0e0;
  }
  
  .confirmation-popup button:active {
    background-color: #ccc;
  }
  


:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins",sans-serif;
    font-size: 45px;
    font-weight: 500;
}



.ap{
    display: block;
    height: 100%;
    padding-left: 10px;
    color: bg-primary;
    animation: spin_words 10s infinite;
}
@keyframes spin_words{
    10%{
        transform: translateY(-112%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-412%);
    }
    100%{
        transform: translateY(-400%);
    }
}





/* Initial style for the image */
.image-filter {
  filter: none; /* No filter by default */
  transition: filter 0.3s ease; /* Add a smooth transition effect */
}

/* Apply a filter when hovering over the image */
.image-filter:hover {
  filter: invert(100%) hue-rotate(240deg); /* Change the filter effect on hover to invert and set the hue-rotate to 240 degrees for the specified color */
}



